import { useState } from "preact/hooks";

interface JsonFetcherProps {
  resultFilePath: string; // The path to the result file to be fetched
}

export default function JsonFetcher({ resultFilePath }: JsonFetcherProps) {
  const [jsonContent, setJsonContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch JSON via API
  const fetchJson = async () => {
    setLoading(true);
    setError(null); // Clear previous errors

    try {
      const response = await fetch(`/api/get-result?resultFilePath=${encodeURIComponent(resultFilePath)}`);
      if (!response.ok) {
        throw new Error(`Failed to load JSON file: ${response.statusText}`);
      }
      const data = await response.json();
      setJsonContent(JSON.stringify(data, null, 2)); // Pretty-print JSON
    } catch (err) {
      setError(err.message || "Unknown error occurred while fetching JSON.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div class="mt-4">
      <button
        onClick={fetchJson}
        class="bg-blue-500 text-white px-4 py-2 rounded"
        disabled={loading}
      >
        {loading ? "Loading..." : "Result JSON"}
      </button>

      {error && (
        <p class="text-red-500 mt-2">Error: {error}</p>
      )}

      {jsonContent && (
        <div class="mt-4">
          <h2 class="text-lg font-bold">JSON Output</h2>
          <pre class="p-4 bg-black text-green-400 border rounded h-96 overflow-auto font-mono whitespace-pre-wrap resize-y">
            {jsonContent}
          </pre>
        </div>
      )}
    </div>
  );
}
